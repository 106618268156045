import React from "react"
import "../styles/WikiItem.scss"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { TrickMdl } from "/src/cchelper"

export default function WikiItem(props) {
  // title, date, image, excerpt, tags
  var { post, missingImg, prefix } = props
  //var img = has(post,"image.image.childImageSharp.fluid") ? post.image.image.childImageSharp.fluid : missingImg.fluid;
  var path = prefix + "/" + post.slug

  if (!(post instanceof TrickMdl)) post = new TrickMdl(post)
  var imgsrc = getImage(post?.image?.image)
  var img
  if (imgsrc) {
    img = (
      <GatsbyImage
        image={post.image.image.childImageSharp.gatsbyImageData}
        objectFit="cover"
        alt="a cool trick"
      />
    )
  } else if (post.videoThumb) {
    img = <img src={post.videoThumb} alt="post images" />
  } else {
    img = <GatsbyImage image={missingImg.gatsbyImageData} objectFit="cover" />
  }

  const art = (
    <article className="wiki-item">
      <h3 className="wiki-item-title">
        <Link to={path}>{post.title}</Link>
      </h3>
      <div className="wiki-item-image">
        <Link to={path}>{img}</Link>
      </div>
      <div className="wiki-item-type">
        <Link to={`${prefix}/${post.skill}`}>{post.skill}</Link>
      </div>
      {/* <TagList className='tags2' prefix={''} tags={post.tags}></TagList> */}
      <div className="wiki-item-level">
        <Link to={`${prefix}/${post.skill}/nivel/${post.level}`}>
          {post.level}
        </Link>
      </div>
    </article>
  )
  return art
}

/* 
title, image.url, level.name 
tags.name, createdAt, content, 
aka, name, numPeople, type, basePos.name, 
flyerPos.name
*/
