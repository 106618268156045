import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import PostMosaic from "../components/PostMosaic"
import Paginate from "../components/Paginate"
import { DuoPoseMdl } from "/src/cchelper"

const MosaicTemplate = ({ pageContext, location }) => {
  var {
    category,
    posts,
    numPages,
    currentPage,
    missingImg,
    prefix,
    menuData,
  } = pageContext
  posts = posts.map(p => new DuoPoseMdl(p))

  return (
    <Layout menuData={menuData} location={location}>
      <Seo title="Wiki Duo" />
      <PostMosaic
        lista={posts}
        category={category}
        missingImg={missingImg}
        prefix={prefix}
      />
      <Paginate
        numPages={numPages}
        prefix={prefix}
        category={category}
        current={currentPage}
      ></Paginate>
    </Layout>
  )
}

export default MosaicTemplate
