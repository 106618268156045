import React from "react"
import WikiItem from "./WikiItem"
import "../styles/PostMosaic.scss"

export default function WikiMosaic(props) {
  var { lista, missingImg, prefix, category } = props
  var wikiItemList = lista.map((item, index) => {
    var res = (
      // title, date, image, excerpt, tags
      <WikiItem
        key={index}
        post={item}
        missingImg={missingImg}
        prefix={prefix}
        category={category}
      />
    )
    return res
  })

  return <div className="wiki-list">{wikiItemList}</div>
}
