import React from "react"
import { Link } from "gatsby"

export default function Paginate(props) {
	var {numPages= 0, prefix= '', current=0, category='' } = props;
	
	var pages = Array.from({ length: numPages }).map( (key,item)=>{
		var page = item===0? '' : item+1;
		var active = current === item+1? 'active':'';
		return <div key={item}>
			<Link activeClassName={active}  
				to={`${prefix+category}/${page}`}>{item+1}</Link>
			</div>
	});

	return <div className='pagination'>{pages}</div>

}